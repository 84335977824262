import React from 'react'
import Slider from "react-slick";
import {useTranslation} from "react-i18next";

export const Revista = (props) => {
  const [t, i18n] = useTranslation('common');
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [{
      breakpoint: 1180,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }, {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 780,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  };
  const divs = props.images.map((data, index) =>
    // <div key={index} className={data.class} sample={data.sample}  id={data.id}>
    
    <div key={index} className={`samples ${data.class} p-5 revistaDiv`} sample={data.sample}  id={data.id}>
      <p><img src={data.src}  id={data.id} alt={data.alt} /></p>
      <div className='text-center mt-3'>
        <p className='text-unnamed text-black'>
          {data.Text1}
        </p>
      </div>
    </div>
  );
  return (
    <div className='row m-0 p-0 justify-content-center'>
      <div className='my-3 d-flex col-12 col-sm-10 col-md-8 col-lg-8 col-xl-7 col-xxl-7 justify-content-end'>
        <div className='col-12 col-sm-10 col-md-8 col-lg-8 col-xl-7 col-xxl-7'>
          <img src={props.image1} alt="" className={`img-fluid ${t("clase.es")}`}/>
          <img src={props.image2} alt="" className={`img-fluid ${t("clase.en")}`} />
        </div>
      </div>
      <main className='mt-5'>
        <div className='content'>
          <div className="carousel clearfix">

            <div className="carousel-view clearfix">
              <Slider {...settings}>
                {divs}
              </Slider>
            </div>
          </div>
        </div>

      </main>
      <div className="turn-modal m-0 p-0" id="flipbook">
        <button className="cmd-close"><span>X</span></button>
        <div className="mount-container">
          <div id="sample-viewer" style={{ width: "100%", height: "100vh" }}>
            <div id="replace" tyle={{ width: "100%", height: "100vh" }}>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
